import React, { useEffect, useState } from 'react';

const ArtworkList = () => {
    const [artworks, setArtworks] = useState([]);

    useEffect(() => {
        const fetchArtworks = async () => {
            const response = await fetch('/artworks.json');
            const data = await response.json();
            setArtworks(data);
        };
        fetchArtworks();
    }, []);

    return (
        <div className="artwork-list">
            <h2>Artworks</h2>
            {artworks.map((artwork) => (
                <div key={artwork.id} className="artwork-item">
                    <h3>{artwork.title}</h3>
                    <img src={artwork.src} alt={artwork.title} />
                </div>
            ))}
        </div>
    );
};

export default ArtworkList;
