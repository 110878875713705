import React, { useEffect, useState } from 'react';

const PodcastList = () => {
    const [podcasts, setPodcasts] = useState([]);

    useEffect(() => {
        const fetchPodcasts = async () => {
            const response = await fetch('/podcasts.json');
            const data = await response.json();
            setPodcasts(data);
        };
        fetchPodcasts();
    }, []);

    return (
        <div className="podcast-list">
            <h2>Podcast一覧</h2>
            {podcasts.slice().reverse().map((podcast) => (
                <PodcastItem key={podcast.id} podcast={podcast} />
            ))}
        </div>
    );
};

const PodcastItem = ({ podcast }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="podcast-item">
            <div className="podcast-header">
                <h3>{podcast.title}</h3>
                <h4>{podcast.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}</h4>
                <audio controls style={{ width: "100%" }}>
                    <source src={podcast.src} type="audio/mpeg" />
                    お使いのブラウザはaudio要素をサポートしていません。
                </audio>
            </div>
            <button onClick={toggleExpand} className="toggle-button">
                {isExpanded ? '閉じる' : '詳細表示'}
            </button>
            {isExpanded && (
                <div className="podcast-details">
                    <img src={podcast.image} alt="Podcast" className="podcast-image" />
                    <p className="additional-text">
                        {podcast.additionalText && podcast.additionalText.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                </div>
            )}
        </div>
    );
};

export default PodcastList;